<template>
  <v-dialog
    content-class="dialog"
    v-bind="$attrs"
    v-on="$listeners"
    width="420"
  >
    <!--    overlay-color="black"-->
    <!--    overlay-opacity="0.75"-->
    <v-card class="dialog__container px-6 py-5 py-sm-15 px-sm-11">
      <h2 class="dialog__title">Establecer contraseña</h2>
      <h3 class="dialog__subtitle">Escribe una nueva contraseña para tu cuenta de USAMEDIC.</h3>
      <v-form @submit.prevent="doChangePassword" class="" ref="form">
        <div class="dialog__input-wrapper  d-flex flex-column justify-space-evenly">
          <v-text-field
            outlined
            class="flex-grow-0"
            rounded
            label="Contraseña"
            v-model="form.new_password1"
            :append-icon="passwordVisible1 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="passwordVisible1 ? 'text' : 'password'"
            @click:append="passwordVisible1 = !passwordVisible1"
            :rules="[rules.required, rules.minLength(8), rules.containNumber, rules.containSymbol, rules.containUpperCase, rules.containLowerCase ]"
            @focus="errorMessages = ''"
            :error="errorMessages != ''"
            :error-messages="errorMessages"
          />
          <v-text-field
            outlined
            class="flex-grow-0"
            rounded
            label="Confirmar contraseña"
            v-model="form.new_password2"
            :rules="[rules.required, rules.minLength(8), rules.equalTo('contraseña', form.new_password1)]"
            :append-icon="passwordVisible2 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="passwordVisible2 ? 'text' : 'password'"
            @click:append="passwordVisible2 = !passwordVisible2"
            @focus="errorMessages = ''"
            :error="errorMessages != ''"
            :error-messages="errorMessages"
          />

        </div>
        <UsamedicButton
          class="full-width spacing-mobile-btn mt-auto"
          type="submit"
          :loading="loadingBtn"
          style="width :100%;"
        >
          Guardar
        </UsamedicButton>

      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import UsamedicButton from '@/components/buttons/UsamedicButton'
import axios from 'axios'
import { equalTo, minLength, required, containNumber, containSymbol, containUpperCase, containLowerCase } from '@/utils/rules'

export default {
  name: 'ChangePasswordModal',
  inheritAttrs: false,
  components: {
    UsamedicButton
  },
  data () {
    return {
      form: {
        new_password1: '',
        new_password2: ''
      },
      rules: { required, minLength, equalTo, containNumber, containSymbol, containUpperCase, containLowerCase },
      passwordVisible1: false,
      passwordVisible2: false,
      loadingBtn: false,
      errorMessages: ''
    }
  },
  methods: {
    async doChangePassword () {
      const payload = { ...this.form }
      if (!this.$refs.form.validate()) {
        return
      }
      try {
        this.loadingBtn = true
        await this.$root.$loading.open({})
        await axios.post('api/rest-auth/password/change/', payload)
        this.loadingBtn = false
        await this.$root.$confirm({
          title: 'FELICIDADES!',
          message: 'Tu contraseña se cambió correctamente',
          acceptText: 'Aceptar'
        })
        this.$refs.form.reset()
        this.$refs.form.resetValidation()
      } catch (e) {
        this.errorMessages = e.response.data.new_password2 || ''
        await this.$root.$confirm({
          title: 'UPS!',
          message: 'Hubo un error al cambiar la contraseña',
          acceptText: 'Aceptar'
        })
      } finally {
        await this.$root.$loading.close()
        this.$emit('processEnd')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.dialog {
  &__container {
    border-radius: 15px !important;
  }
  &__input-wrapper {
      height: 250px;
  }
  &__title {
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #232323;
    font-weight: normal;
    margin-bottom: 37px;
  }

  &__subtitle {
    font-size: 12px;
    line-height: 18px;
    color: #232323;
    font-weight: normal;
    text-align: justify;
  }
}
</style>
