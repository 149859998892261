<template>
  <div class="px-5 py-10 d-flex flex-column align-center" fluid>
    <p class="section__text--title d-flex justify-start mr-auto" :class="smAndDown ? 'ml-5': 'ml-10'" style="text-align: start !important;">Perfil</p>
    <v-card class="profile">
      <v-row class="pa-0 my-15 full-height" :class="smAndDown ? 'px-12': 'px-15 mx-5'" justify="center" align="center">
        <v-col cols="12" md="6" class="d-flex flex-column pa-0" :class="{'align-center px-10': !isMobile}">
          <v-btn
            height="97"
            width="97"
            class="fotoperfil mb-10 align-self-start field"
            @click="openLogoInput"
            exact
            :class="{'ml-5': !isMobile}"
          >
            <input
              type="file"
              accept="image/*"
              ref="logoInput"
              v-show="false"
              @change="doUploadAvatar"
            >
            <v-img
              height="97"
              max-width="97"
              style="cursor: pointer;"
              :src="profilePhoto"
              class="fotoperfil ml-6"
            />
            <v-icon class="mb-0 mt-auto">mdi-square-edit-outline</v-icon>
          </v-btn>
          <p class="field__text mt-5">Nombres</p>
          <v-text-field
            v-model="userEdited.first_name"
            solo
            class="field"
            :rules="[rules.required]"
            ref="firstname"
          ></v-text-field>
          <p class="field__text">Apellidos</p>
          <v-text-field
            v-model="userEdited.last_name"
            solo
            class="field"
            :rules="[rules.required]"
            ref="lastname"
          ></v-text-field>
          <p class="field__text">Correo</p>
          <v-text-field
            v-model="userEdited.email"
            solo
            type="email"
            class="field"
            :rules="[rules.required]"
            ref="email"
            disabled
          ></v-text-field>
          <p class="field__text">Contraseña</p>
          <v-text-field
            solo
            value="passwordtest"
            class="field"
            type="password"
            @focus="openChangePasswordModal()"
            @click:append="openChangePasswordModal()"
            ref="password"
            append-icon="mdi-pencil"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" class="d-flex flex-column fill-height pa-0" :class="{'align-center px-10': !isMobile}">
            <p class="field__text">Institución</p>
            <v-select
              :items="institutions"
              item-text="nombre"
              item-value="id"
              v-model="userEdited.institucion.id"
              solo
              class="field"
              color="primary"
              ref="institution"
            />
            <p class="field__text">Género</p>
            <v-select
              :items="genders"
              item-text="nombre"
              item-value="id"
              v-model="userEdited.genero.id"
              solo
              class="field"
              color="primary"
              ref="gender"
            />
            <div class="d-flex field">
              <div class="mr-5">
                <p class="field__text field__region">Pais</p>
                <v-select
                  :items="countries"
                  item-text="name"
                  item-value="id"
                  v-model="userEdited.region.country.id"
                  @change="fetchRegiones(userEdited.region.country.id)"
                  solo
                  class="field fill-width"
                  color="primary"
                />
              </div>
              <div>
                <p class="field__text field__region">Ciudad</p>
                <v-select
                  :items="regions"
                  item-text="name"
                  item-value="id"
                  v-model="userEdited.region.id"
                  solo
                  class="field fill-width"
                  color="primary"
                  no-data-text="Seleccione un país"
                />
              </div>
            </div>
            <p class="field__text">Celular</p>
            <div class="d-flex field">
              <v-text-field
                v-model="phoneCode"
                solo
                disabled
                class="field mr-5"
                style="width: 30%"
                ref="phoneCode"
              ></v-text-field>
              <v-text-field
                v-model="userEdited.celular"
                solo
                type="Number"
                class="field"
                style="width: 70%"
                :rules="[rules.required]"
                ref="cellphone"
              ></v-text-field>
            </div>
            <v-menu
              ref="fechaNacimiento"
              v-model="openDates"
              :close-on-content-click="false"
              class="field"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="userEdited.fecha_nacimiento"
                  readonly
                  :rules="[rules.required]"
                  v-bind="attrs"
                  v-on="on"
                  solo
                  class="field"
                  ref="fechaNacimiento"
                ></v-text-field>
              </template>
              <v-date-picker
                ref="picker"
                v-model="userEdited.fecha_nacimiento"
                @change="openDates = false"
                class="datepicker"
                header-color="primary"
                max="2010-01-01"
              ></v-date-picker>
            </v-menu>
            <UsamedicButton
              :width="smAndDown ? '100%': '90%'"
              @click="doEditUser"
            >
              Guardar
            </UsamedicButton>
        </v-col>
      </v-row>
    </v-card>
    <ChangePasswordModal :persistent="false" v-model="changePasswordModal" @processEnd="changePasswordModal=false"/>
    <LoadingModal v-model="loading"></LoadingModal>
    <AlertaModal
      @accept="confirmarPhoto"
      v-model="showPhotoAlert"
      :titulo="photoAlertTitle"
      :mensaje="photoAlertMessage"
    />
  </div>
</template>

<script>
import { required, email } from '@/utils/rules'
import { mapState, mapActions, mapGetters } from 'vuex'
import LoadingModal from '@/components/emergentesGlobales/LoadingModal'
import UsamedicButton from '@/components/buttons/UsamedicButton'
import ChangePasswordModal from '@/components/profile/ChangePasswordModal'
import AlertaModal from '@/components/emergentesGlobales/AlertaModal'

export default {
  name: 'Profile',
  components: {
    LoadingModal,
    UsamedicButton,
    ChangePasswordModal,
    AlertaModal
  },
  data () {
    return {
      loading: false,
      openDates: false,
      changePasswordModal: false,
      showPhotoAlert: false,
      photoAlertTitle: '',
      photoAlertMessage: '',
      userEdited: {
        username: '',
        email: '',
        foto: '',
        first_name: '',
        last_name: '',
        celular: '',
        region: {
          id: '',
          name: '',
          country: {
            id: '',
            name: ''
          }
        },
        institucion: {
          id: '',
          nombre: ''
        },
        genero: {
          id: '',
          nombre: ''
        },
        fecha_nacimiento: ''
      },
      password: '',
      rules: {
        required,
        email
      }
    }
  },
  methods: {
    ...mapActions('profile', ['fetchUser', 'updateUser', 'fetchPaises', 'fetchRegiones', 'fetchInstituciones', 'fetchGeneros', 'updatePhoto']),
    openChangePasswordModal () {
      this.changePasswordModal = true
    },
    openLogoInput () {
      this.$refs.logoInput.click()
    },
    confirmarPhoto () {
      this.showPhotoAlert = false
    },
    confirmar () {
      this.$router.push({ name: 'Inicio' })
    },
    async doUploadAvatar () {
      try {
        this.loading = true
        const foto = this.$refs.logoInput.files[0]
        const payload = new FormData()
        payload.append('foto', foto)
        await this.updatePhoto(payload)
        const userUpdated = this.user
        this.userEdited.foto = userUpdated.foto
        await this.$root.$confirm({
          title: '¡Felicidades!',
          message: 'Su foto de perfil ha sido actualizada.',
          acceptText: 'Aceptar'
        })
      } catch (e) {
        await this.$root.$confirm({
          title: 'Lo sentimos',
          message: 'Ha ocurrido un error actualizando su foto de perfil. Intentelo nuevamente en unos minutos.',
          acceptText: 'Aceptar'
        })
      } finally {
        this.loading = false
      }
    },
    async doEditUser () {
      this.loading = true
      try {
        this.userEdited.institucion_id = this.userEdited.institucion.id || null
        this.userEdited.genero_id = this.userEdited.genero.id || null
        this.userEdited.region_id = this.userEdited.region.id || null
        delete this.userEdited.foto
        await this.updateUser(this.userEdited)
        await this.$root.$confirm({
          title: '¡Felicidades!',
          message: 'Se ha actualizado tu perfil de usuario.',
          acceptText: 'Aceptar'
        })
      } catch (e) {
        await this.$root.$confirm({
          title: '¡Lo sentimos!',
          message: 'Ha ocurrido un error actualizando su foto de perfil. Intentelo nuevamente en unos minutos.',
          acceptText: 'Aceptar'
        })
      } finally {
        this.loading = false
      }
    }
  },
  computed: {
    ...mapState('profile', ['user', 'countries', 'regions', 'institutions', 'genders']),
    ...mapGetters('profile', ['profilePhoto']),
    phoneCode () {
      const paisId = this.userEdited.region.country.id
      const pais = this.countries.filter((pais) => pais.id === paisId)[0] || null
      const paisCode = pais ? `+${pais.phone}` : null
      return paisCode
    },
    smAndDown () {
      return this.$vuetify.breakpoint.smAndDown
    },
    isMobile () {
      return this.$vuetify.breakpoint.mobile
    }
  },
  async mounted () {
    await this.fetchUser()
    this.userEdited = this.user
    if (!this.userEdited.region) {
      this.userEdited.region = {
        id: '',
        name: '',
        country: {
          id: '',
          name: '',
          phone: ''
        }
      }
    }
    if (!this.userEdited.institucion) {
      this.userEdited.institucion = {
        id: '',
        name: ''
      }
    }
    if (!this.userEdited.genero) {
      this.userEdited.genero = {
        id: '',
        name: ''
      }
    }
    await this.fetchInstituciones()
    await this.fetchGeneros()
    await this.fetchPaises()
    if (this.userEdited.region.id !== '') {
      await this.fetchRegiones(this.userEdited.region.country.id)
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  background: #FFFFFF !important;
  border: 1px solid #E5E5E5 !important;
  box-sizing: border-box !important;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15) !important;
  border-radius: 10px !important;
}
.profile {
  width: 95% !important;
  min-height: 543px !important;
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15) !important;
  border-radius: 10px !important;
}
.field {
  // border: 1px solid #B7B7B7;
  // box-sizing: border-box;
  border-radius: 15px !important;
  width: 90%;
  // width: 260px;
  &__region {
    margin-left: 0;
  }
}
.fotoperfil {
  border-radius: 50% !important;
}
.datepicker {
  width: 100% !important;
}
@media (max-width: 960px) {
  .field {
    width: 100%;
    &__region {
      margin-left: 10px;
    }
  }
  .profile {
    width: 100%;
  }
}
</style>
