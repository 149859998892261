<template>
  <v-btn
    v-bind="$attrs"
    v-on="$listeners"
    large
    rounded
    class="boton-usamedic white--text"
  >
    <slot/>
  </v-btn>
</template>
<script>
export default {
  inheritAttrs: false
}
</script>

<style scoped lang="scss">
.boton-usamedic {
  background: linear-gradient(90deg, rgba(31, 193, 208, 1) 0%, rgba(31, 193, 208, 1) 5%, rgba(4, 128, 218, 1) 90%);
  font-weight: 600;
  font-size: 13px;
  line-height: 19px;
  text-transform: none;
  letter-spacing: normal;
}
</style>
