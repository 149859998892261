<template>
    <v-dialog v-bind="$attrs" v-on="$listeners" max-width="290">
        <v-card>
            <v-card-title class="headline text-center">{{titulo}}</v-card-title>
            <v-card-text> {{mensaje}}</v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="aceptar">
                    OK
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
  name: 'AlertaModal',
  props: {
    titulo: String,
    mensaje: String
  },
  inheritAttrs: false,
  methods: {
    aceptar () {
      this.show = false
      this.$emit('accept')
    }
  }
}
</script>

<style scoped>

</style>
